/* If you need to add @import statements, do so up here */

@import "jit-refresh.css";
/* triggers frontend rebuilds */

/* Set up Tailwind imports */
@tailwind base;
@tailwind components;
@tailwind utilities;

.multilingual-font-stack {
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui,
    helvetica neue, helvetica, Cantarell, Ubuntu, roboto, noto, arial,
    sans-serif;
  font-size: 1rem;
  /* 16px */
}

/* Arabic */
.Arab .font-serif,
.Arab.font-serif,
.font-serif .Arab {
  font-family: "Noto Naskh Arabic", serif;
}

.Arab .font-sans,
.Arab.font-sans,
.font-sans .Arab {
  font-family: "Noto Sans Arabic", sans-serif;
}

.Arab ol {
  list-style-type: arabic-indic;
}

/* Persian */
.Aran .font-serif,
.Aran.font-serif,
.font-serif .Aran {
  font-family: "Scheherazade New", serif;
}

.Aran .font-sans,
.Aran.font-sans,
.font-sans .Aran {
  font-family: "Scheherazade New", sans-serif;
}

.Aran ol {
  list-style-type: persian;
}


/* Cyrillic */
.Cyrl .font-serif,
.font-serif .Cyrl {
  font-family: "EB Garamond", serif;
}

.Cyrl .font-sans,
.Cyrl.font-sans,
.font-sans .Cyrl {
  font-family: "Inter", sans;
}

/* Devanagari */
.Deva .font-serif,
.Deva.font-serif,
.font-serif .Deva {
  font-family: "Noto Serif Devanagari", sans-serif;
}

.Deva .font-sans,
.Deva.font-sans,
.font-sans .Deva {
  font-family: "Noto Sans Devanagari", sans-serif;
}

.Deva ol {
  list-style-type: devanagari;
}


/* Han (Traditional variant) */
.Hans .font-serif,
.Hans.font-serif,
.font-serif .Hans {
  font-family: "Noto Serif SC", serif;
}

.Hans .font-sans,
.Hans.font-sans,
.font-sans .Hans {
  font-family: "Noto Sans SC", sans-serif;
}

.Hans ol {
  list-style-type: trad-chinese-informal;
}

/* Japanese */
.Jpan .font-serif,
.Jpan.font-serif,
.font-serif .Jpan {
  font-family: "Noto Serif JP", serif;
}

.Jpan .font-sans,
.Jpan.font-sans,
.font-sans .Jpan {
  font-family: "Noto Sans JP", serif;
}

.Jpan ol {
  list-style-type: japanese-informal;
}

/* Latin */
.Latn .font-serif,
.Latn.font-serif,
.font-serif .Latn {
  font-family: "EB Garamond", serif;
}

.Latn .font-sans,
.Latn.font-sans,
.font-sans .Latn {
  font-family: "Inter", sans;
}

/* Latin sans serif size override classes
   - required because Inter has a smaller x-height than serif font */
.Latn .text-sans-xs {
  font-size: 0.75rem;
  /* 12px */
}

.Latn .text-sans-sm {
  font-size: 0.875rem;
  /* 14px */
}

.Latn .text-sans-base {
  font-size: 1rem;
  /* 16px */
}

.Latn .text-sans-lg {
  font-size: 1.125rem;
  /* 18px */
}

.Latn .text-sans-xl {
  font-size: 1.25rem;
  /* 20px */
}

.Latn .text-sans-2xl {
  font-size: 1.5rem;
  /* 24px */
}

.Latn .text-sans-3xl {
  font-size: 1.875rem;
  /* 30px */
}

.Latn .text-sans-4xl {
  font-size: 2.25rem;
  /* 36px */
}

.Latn .text-sans-5xl {
  font-size: 3rem;
  /* 48px */
}

.Latn .text-sans-6xl {
  font-size: 3.75rem;
  /* 60px */
}

.Latn .text-sans-7xl {
  font-size: 4.5rem;
  /* 72px */
}

.Latn .text-sans-8xl {
  font-size: 6rem;
  /* 96px */
}

.Latn .text-sans-9xl {
  font-size: 8rem;
  /* 128px */
}

/* Cyrillic sans serif size override classes
   - required because Inter has a smaller x-height than serif font */
.Cyrl .text-sans-xs {
  font-size: 0.75rem;
  /* 12px */
}

.Cyrl .text-sans-sm {
  font-size: 0.875rem;
  /* 14px */
}

.Cyrl .text-sans-base {
  font-size: 1rem;
  /* 16px */
}

.Cyrl .text-sans-lg {
  font-size: 1.125rem;
  /* 18px */
}

.Cyrl .text-sans-xl {
  font-size: 1.25rem;
  /* 20px */
}

.Cyrl .text-sans-2xl {
  font-size: 1.5rem;
  /* 24px */
}

.Cyrl .text-sans-3xl {
  font-size: 1.875rem;
  /* 30px */
}

.Cyrl .text-sans-4xl {
  font-size: 2.25rem;
  /* 36px */
}

.Cyrl .text-sans-5xl {
  font-size: 3rem;
  /* 48px */
}

.Cyrl .text-sans-6xl {
  font-size: 3.75rem;
  /* 60px */
}

.Cyrl .text-sans-7xl {
  font-size: 4.5rem;
  /* 72px */
}

.Cyrl .text-sans-8xl {
  font-size: 6rem;
  /* 96px */
}

.Cyrl .text-sans-9xl {
  font-size: 8rem;
  /* 128px */
}

.text-link {
  @apply text-blue-600 hover:underline hover:text-blue-800;
}

.text-link-underline {
  @apply underline decoration-1 underline-offset-2 decoration-blue-200 hover:decoration-blue-800;
}

.text-link-underline-group * {
  @apply underline decoration-1 underline-offset-2 decoration-blue-200;
}

.text-link-underline-group:hover * {
  @apply text-blue-800 decoration-blue-800;
}




[dir="rtl"] .prose a[href*="//"]:not([href*="ruhi.org"]) {
  unicode-bidi: plaintext;
  /* fix for external link icon showing in wrong place in RTL languages when LTR text i included in link  */
}

.prose a[href*="//"]:not([href*="ruhi.org"]) {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='2' stroke='rgb(2 132 199)' aria-hidden='true'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14'/%3E%3C/svg%3E");
  /* https://www.svgbackgrounds.com/tools/svg-to-css/ */
  /* stroke: text-blue-600 */
  @apply ltr:pr-[18px] rtl:pl-[18px] ltr:bg-right rtl:bg-left bg-no-repeat bg-opacity-90;
  background-size: 1rem;
}

.prose a[href*="//"]:not([href*="ruhi.org"]):hover {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='2' stroke='rgb(7 89 133)' aria-hidden='true'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14'/%3E%3C/svg%3E");
  /* stroke: text-blue-800 */
}

.prose {
  @apply max-w-none;
  @apply prose-headings:font-normal;
  @apply prose-p:text-lg prose-p:leading-relaxed;
  @apply prose-a:font-normal prose-a:text-blue-600 hover:prose-a:underline hover:prose-a:text-blue-800;
  @apply prose-a:decoration-1 prose-a:underline-offset-2 prose-a:decoration-blue-200 hover:prose-a:decoration-blue-800;
  @apply prose-strong:font-medium prose-strong:text-current;
  @apply prose-blockquote:font-normal prose-blockquote:not-italic prose-blockquote:text-current prose-blockquote:border-none prose-blockquote:ps-8;
  @apply prose-hr:border-none prose-hr:text-center prose-hr:mt-0 prose-hr:overflow-visible prose-hr:after:content-['﹡_﹡_﹡'];
  @apply prose-ol:px-0 ltr:prose-ol:pl-6 rtl:prose-ol:pr-6;
  @apply prose-ul:px-0 ltr:prose-ul:pl-6 rtl:prose-ul:pr-6;
  @apply prose-li:px-0 ltr:prose-li:pl-1.5 rtl:prose-li:pr-1.5;
}

.prose blockquote p:before,
.prose blockquote p:after {
  content: "";
}

.float-pic {
  @apply flex flex-col gap-4 ms-6 float-end sm:flex-row;
}

.float-pic img {
  @apply md:-mt-1 md:mb-6;
}

.prose a[href^="#fn-"] {
  @apply w-5 h-5 p-1 text-xs no-underline align-super hover:bg-blue-200;
}

.icon-play,
.icon-loading,
.icon-pause {
  @apply hidden;
}

.is-paused .icon-play {
  @apply block;
}

.is-loading .icon-loading {
  @apply block;
}

.is-playing .icon-pause {
  @apply block;
}

.mobile-menu-item.open {
  @apply bg-rtan-100;
}
